<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">

        <b-card>
          <b-row>
            <b-col cols="12">
              <b-spinner v-if="isLoading" />
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="!isLoading"
                v-html="html"
              />
              <!-- eslint-enable vue/no-v-html -->
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col cols="12">
              <b-spinner v-if="isLoading" />

              <b-form-textarea
                v-if="!isLoading"
                v-model="html"
                rows="20"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import InformativeService from '@/services/informativeService'
import {
  BFormTextarea,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BBreadcrumb,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BBreadcrumb,
  },

  data() {
    return {
      isLoading: true,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Informativos',
          to: { name: 'cms-informative' },
        },
        {
          text: 'Html',
          active: true,
        },
      ],
      informativeService: null,
      html: '',
    }
  },

  created() {
    this.informativeService = new InformativeService()
    this.getHtml()
  },

  methods: {
    getHtml() {
      this.isLoading = true
      this.informativeService.html(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.html = response.data
        }
        this.isLoading = false
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
